import { filterTypes } from '~/enums/FilterTypes';
import { TenantFilter, OccupationFilter, ApplicantTermsOfServiceStateFilter, UserVerificationStateFilter, TFilterSearch, TFilterBoolean } from '~/components/Filters';
export default (function () {
  var i18n = useNuxtApp().nuxt2Context.i18n;
  var availableFilters = function availableFilters() {
    return [{
      key: 'search',
      component: TFilterSearch,
      type: filterTypes.STRING,
      props: {
        label: i18n.t('global.actions.search')
      }
    }, {
      key: 'occupations.id',
      component: OccupationFilter,
      type: filterTypes.ARRAY
    }, {
      key: 'tenant_id',
      component: TenantFilter,
      type: filterTypes.ARRAY
    }, {
      key: 'tos_accepted',
      component: ApplicantTermsOfServiceStateFilter,
      type: filterTypes.BOOLEAN
    }, {
      key: 'user.verified',
      component: UserVerificationStateFilter,
      type: filterTypes.BOOLEAN
    }, {
      key: 'pinged',
      component: TFilterBoolean,
      type: filterTypes.BOOLEAN,
      props: {
        label: i18n.t('fields.pinged')
      }
    }];
  };
  return {
    availableFilters: availableFilters
  };
});