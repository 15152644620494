<template>
  <t-page>
    <template #header>
      <h3>{{ $t('pages.applicant.index.heading') }}</h3>
    </template>
    <t-grid>
      <t-grid-cell>
        <index-table :query="query" :fields="fields" :filters="filters" />
      </t-grid-cell>
    </t-grid>
  </t-page>
</template>

<script>
import Vue from 'vue';
import Applicant from '~/models/Applicant';
import IndexTable from '~/pages/shared/applicants/partials/IndexTable';

export default Vue.extend({
  components: { IndexTable },

  middleware: 'tenantmember',

  data: () => ({
    query: () => new Applicant().include('occupation', 'organization'),

    filters: ['tos_accepted', 'user.verified', 'pinged'],

    fields: [
      'organization.name',
      'occupation',
      'address.city',
      'available',
      'organizations_count',
      'created_at',
      'actions',
    ],
  }),
});
</script>
