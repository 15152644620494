<template>
  <div>
    <div class="t-table-v2__cell__primary">
      <strong>{{ user.fullname }}</strong>
    </div>
    <div class="t-table-v2__cell__secondary">
      {{ user.email }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>
