class ApplicantAvailabilityEnum {
  static AVAILABLE = true;
  static UNAVAILABLE = false;

  static labelKey(item: boolean) {
    return ApplicantAvailabilityEnum.meta(item)?.labelKey;
  }

  static tagType(item: boolean) {
    return ApplicantAvailabilityEnum.meta(item)?.tagType;
  }

  static meta(item: boolean) {
    switch (item) {
      case ApplicantAvailabilityEnum.AVAILABLE:
        return {
          labelKey: 'global.statuses.available',
          tagType: 'success',
        };
      case ApplicantAvailabilityEnum.UNAVAILABLE:
        return {
          labelKey: 'global.statuses.unavailable',
          tagType: 'warning',
        };
    }
  }

  static all() {
    return [
      {
        key: ApplicantAvailabilityEnum.AVAILABLE,
        labelKey: ApplicantAvailabilityEnum.labelKey(ApplicantAvailabilityEnum.AVAILABLE),
      },
      {
        key: ApplicantAvailabilityEnum.UNAVAILABLE,
        labelKey: ApplicantAvailabilityEnum.labelKey(ApplicantAvailabilityEnum.UNAVAILABLE),
      },
    ];
  }
}

export default ApplicantAvailabilityEnum;
